import React, { useEffect } from 'react';
// import { AnimationOnScroll } from 'react-animation-on-scroll';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, navigate } from 'gatsby';
import { gsap } from 'gsap';
// import SplitType from 'split-type';
import { Footer, Layout } from '../../shared/ui';
import { NewsCircle, NewsRectangle } from '../../assets';
import './news.css';
import { useWindowSize } from '../../shared/hooks';

const News = (props) => {
  // @ts-ignore
  const data = props?.data?.allContentfulNewsList?.nodes;
  const pressData = props?.data?.allContentfulNews?.nodes;
  const mediaScreen = useWindowSize();

  useEffect(() => {
    const ctx = gsap.context(() => {
      // const media = gsap.matchMedia();

      // gsap.utils.toArray('.animated-text').forEach((el: HTMLElement) => {
      //   const splitLines = new SplitType(el, {
      //     types: 'lines',
      //     lineClass:
      //       'overflow-x-visible overflow-y-clip whitespace-nowrap text-line',
      //   });

      //   splitLines.lines.forEach((element) => {
      //     const wrapper = document.createElement('div');
      //     wrapper.className = 'line-wrapper';
      //     element.parentNode?.replaceChild(wrapper, element);
      //     wrapper.appendChild(element);
      //   });

      //   ScrollTrigger.batch(splitLines.lines, {
      //     onEnter: (batch: Element[]) =>
      //       gsap.from(batch, {
      //         opacity: 0,
      //         delay: 0,
      //         y: 100,
      //         duration: 2,
      //         stagger: 0.3,
      //         ease: 'power2.in',
      //         // onComplete: () => splitLines.revert(),
      //       }),
      //     once: true,
      //   });
      // });
      // gsap.utils.toArray('.art').forEach((el: HTMLElement) => {
      //   media.add('(min-width: 640px)', () => {
      //     gsap.to(el, {
      //       y: 100,
      //       duration: 2,
      //       ease: 'power2.out',
      //       scrollTrigger: {
      //         trigger: el,
      //         start: 'top 5%',
      //         scrub: 2,
      //       },
      //     });
      //   });
      // });
      // gsap.utils.toArray('.news-image').forEach((el: HTMLElement) => {
      //   ScrollTrigger.batch(el, {
      //     onEnter: (batch: Element[]) =>
      //       gsap.from(batch, {
      //         opacity: 0,
      //         delay: 0,
      //         y: 100,
      //         duration: 1.5,
      //         ease: 'power2.in',
      //         // onComplete: () => splitLines.revert(),
      //       }),
      //     once: true,
      //   });
      // });
      gsap.to('.heading', {
        scale: 0.3,
        duration: 0.5,
        ease: 'power2.in',
        top: mediaScreen && mediaScreen.width > 640 ? '-8vw' : '-4.5vw',
        mixBlendMode: 'normal',
        // zIndex: 2,
        scrollTrigger: {
          trigger: 'body',
          scrub: 1,
          start: 'top 0px',
          end: 'top 80px',
          // markers: true,
        },
      });
    });

    return () => ctx.revert();
  }, []);

  const handleShrinkImage = (id: string, pId: string) => {
    if (typeof window !== 'undefined') {
      document.getElementById(id).classList.add('shrink-border');
      document.getElementById(pId).classList.add('light-text');
    }
  };
  const handleShrinkOutImage = (id: string, pId: string) => {
    if (typeof window !== 'undefined') {
      document.getElementById(id).classList.remove('shrink-border');
      document.getElementById(pId).classList.remove('light-text');
    }
  };

  return (
    <>
      <Layout transparentNav>
        <div className="head-wrapper">
          <h1 className="heading">News</h1>
        </div>
        {data?.length > 0 &&
          data?.map((item, i) => (
            <div
              key={`news-section-${i}`}
              // eslint-disable-next-line prettier/prettier
              className={`section${item?.order || 1}`}
            >
              <div
                className="news-image"
                onClick={() => navigate(`/news/${item.slug}`)}
              >
                <div
                  // eslint-disable-next-line prettier/prettier
                  id={`shrink${item?.order || 1}`}
                  className="image shrink-image"
                  onMouseEnter={() =>
                    handleShrinkImage(
                      `shrink${item?.order || 1}`,
                      `shrink-text-${item?.order || 1}`
                    )
                  }
                  onMouseLeave={() =>
                    handleShrinkOutImage(
                      `shrink${item?.order || 1}`,
                      `shrink-text-${item?.order || 1}`
                    )
                  }
                />
                <GatsbyImage
                  key={item?.featured_file?.contentful_id}
                  alt={item?.featured_file?.title}
                  image={item?.featured_file?.gatsbyImageData}
                  className="image"
                />
              </div>
              <div
                id={`shrink-text-${item?.order || 1}`}
                className="art"
                onClick={() => navigate(`/news/${item.slug}`)}
                onMouseEnter={() =>
                  handleShrinkImage(
                    `shrink${item?.order || 1}`,
                    `shrink-text-${item?.order || 1}`
                  )
                }
                onMouseLeave={() =>
                  handleShrinkOutImage(
                    `shrink${item?.order || 1}`,
                    `shrink-text-${item?.order || 1}`
                  )
                }
              >
                <span className="animated-text">{item?.title}</span>
                <div className="art-detail">
                  <span className="art-detail-tag animated-text">
                    {' '}
                    <NewsCircle /> {item?.createdAt}
                  </span>
                  <span className="art-detail-tag animated-text">
                    {' '}
                    <NewsRectangle /> {item?.type}
                  </span>
                </div>
              </div>
            </div>
          ))}
        <div className="press-section">
          <h1>Press</h1>
          {pressData?.length > 0 &&
            pressData?.map((item, i) => (
              <p key={`news-press-${i}`}>
                <span className="hover-shade">{item?.title}</span>
              </p>
            ))}
        </div>
      </Layout>
      <Footer />
    </>
  );
};

export const query = graphql`
  query QueryNews {
    allContentfulNewsList(sort: { fields: order, order: ASC }) {
      nodes {
        order
        slug
        title
        type
        featured_file {
          gatsbyImageData
          contentful_id
          title
        }
        file {
          gatsbyImage(width: 1600, height: 900)
          contentful_id
          title
        }
        description {
          raw
        }
        createdAt(formatString: "MMMM DD, YYYY")
      }
    }
    allContentfulNews {
      nodes {
        title
      }
    }
  }
`;

export default News;
